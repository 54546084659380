
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "media-tv-form",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  emit: ["resetForm"],
  props: ["data", "index"],
  setup(props, { emit }) {
    const data_index = ref(props.index);
    const form = ref();
    const upload = ref();
    const is_loading = ref(false);
    const is_show_alert = ref(false);
    const is_confirmed = ref(false);
    const inserted_id = ref("");
    const confirm_button = ref<HTMLElement | null>(null);
    const is_copied = ref(false);
    const loading = ref(false);
    const media_options = ref<Array<any>>([]);
    const stasiun_options = ref<Array<any>>([]);
    const file_list = ref([]);
    const content_value = ref("");
    const account_media_data = ref({});
    const options = ref({
      sentiment: [
        {
          title: "Positif",
          value: "1",
        },
        {
          title: "Netral",
          value: "0",
        },
        {
          title: "Negatif",
          value: "-1",
        },
      ],
      emotion: [
        {
          title: "Netral",
          value: "0",
        },
        {
          title: "Senang",
          value: "1",
        },
        {
          title: "Takut",
          value: "2",
        },
        {
          title: "Sedih",
          value: "3",
        },
        {
          title: "Marah",
          value: "4",
        },
      ],
    });
    const file_upload_header = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const upload_file_url = `${process.env.VUE_APP_API_URL}/streams/uploadfile`;
    const media_tv_data = ref(props.data);
    const form_validator = Yup.object().shape({
      title: Yup.string().required().label("Judul Berita"),
      account: Yup.string().when("source", {
        is: () => media_tv_data.value.account === "",
        then: Yup.string().required("Nama Media is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      jenis_data: Yup.string().when("source", {
        is: () => media_tv_data.value.jenis_data == "",
        then: Yup.string().required("Jenis data is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      ad_value: Yup.string().when("source", {
        is: () => media_tv_data.value.ad_value == "",
        then: Yup.string().required("AD Value is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      program: Yup.string().when("source", {
        is: () => media_tv_data.value.program == "",
        then: Yup.string().required("Program is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      content: Yup.string().when("source", {
        is: (val) => media_tv_data.value.content === "",
        then: Yup.string().required("Konten is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      date: Yup.string().when("source", {
        is: () => media_tv_data.value.date === "",
        then: Yup.string().required("Tanggal Berita is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      file_source: Yup.string().required().label("File Source"),
      emotion: Yup.string().label("Emotion"),
      sentiment: Yup.string().label("Sentimen"),
    });

    // FUNCTION
    const resetForm = () => {
      media_tv_data.value = {
        title: "",
        account: "",
        program: "",
        content: "",
        date: "",
        emotion: "",
        file_source: "",
        journalist: "",
        page: "",
        rate_bw: "",
        rate_fc: "",
        sentiment: "",
        is_news_photo: "",
        jenis_data: "",
        is_validated: false,
      };
      is_confirmed.value = false;
      emit("resetForm", data_index.value);
    };
    const confirmData = () => {
      if (confirm_button.value) {
        is_confirmed.value = true;
        media_tv_data.value.is_validated = true;
      }
    };
    const setFileUpload = (response: any, file: any, fileList: any) => {
      file_list.value = fileList;
      if (fileList.length > 0) {
        media_tv_data.value.file_source = response.file;
      }
    };
    const checkADValueFromDuration = () => {
      if (
        media_tv_data.value.account &&
        media_tv_data.value.date &&
        media_tv_data.value.duration
      ) {
        ApiService.post("/streams/rumus-ad-value", {
          data: {
            nama_media: media_tv_data.value.account,
            publish_date: media_tv_data.value.date,
            durasi_video: media_tv_data.value.duration,
          },
        })
          .then((res) => {
            media_tv_data.value.ad_value = res.data.ad_value || 0;
          })
          .catch(() => {
            media_tv_data.value.ad_value = 0;
          });
      }
    };
    const removeFileUpload = (file: any, fileList: any) => {
      const file_to_remove = file.response.file;
      ApiService.post("/streams/removefile", { data: { path: file_to_remove } })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const errorFileUpload = (error: any, file: any, fileList: any) => {
      let message = JSON.parse(error.message);
      ElMessageBox.alert("Silakan periksa inputan Anda.", message.detail, {
        confirmButtonText: "OK",
        type: "warning",
        center: true,
      });
    };
    const remoteMethod = (query: any) => {
      if (query.length > 2) {
        loading.value = true;
        let url = "/media?media_like=" + query + "&limit=100&page=1";
        if (media_tv_data.value["source"] != "") {
          url = url + "&source=" + media_tv_data.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            media_options.value = response.data.data;
            return media_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        media_options.value = [];
      }
    };
    const remoteMethodTV = (query: any) => {
      if (query.length > 2) {
        loading.value = true;
        let url =
          "/media/stasiuntv?stasiun_like=" + query + "&limit=100&page=1";
        if (media_tv_data.value["source"] != "") {
          url = url + "&source=" + media_tv_data.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            stasiun_options.value = response.data.data;
            return stasiun_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        stasiun_options.value = [];
      }
    };
    const cekFileSource = (file: any, fileList: any) => {
      if (fileList.length == 0) {
        media_tv_data.value["file_source"] = "";
      }
    };
    const convertText = (content_value: any) => {
      if (media_tv_data.value["content"] != null) {
        let text = media_tv_data.value["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        media_tv_data.value.content = newText;
        content_value = newText;
      }
    };
    const changeAccount = (data: any) => {
      account_media_data.value = data;
    };
    const setEmotion = (value: string) => {
      if (value == media_tv_data.value.emotion) {
        media_tv_data.value.emotion = "";
      } else {
        media_tv_data.value.emotion = value;
      }
    };
    const setSentiment = (value: string) => {
      if (value == media_tv_data.value.sentiment) {
        media_tv_data.value.sentiment = "";
      } else {
        media_tv_data.value.sentiment = value;
      }
    };
    const copyText = () => {
      is_copied.value = true;
      var copy = document.getElementById("copy-clipboard") as HTMLInputElement;
      copy.select();
      document.execCommand("copy");
      setTimeout(() => {
        is_copied.value = false;
      }, 1500);
    };

    // WATCHER
    watch(props, () => {
      media_tv_data.value = props.data;
    });

    return {
      data_index,
      media_tv_data,
      form,
      upload,
      is_confirmed,
      is_show_alert,
      is_loading,
      inserted_id,
      confirm_button,
      form_validator,
      loading,
      media_options,
      stasiun_options,
      file_list,
      file_upload_header,
      upload_file_url,
      content_value,
      is_copied,
      options,
      setEmotion,
      setSentiment,
      copyText,
      changeAccount,
      checkADValueFromDuration,
      errorFileUpload,
      convertText,
      cekFileSource,
      remoteMethod,
      remoteMethodTV,
      removeFileUpload,
      confirmData,
      resetForm,
      setFileUpload,
    };
  },
});
